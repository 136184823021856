<template>
  <div class="box-container">
    <v-toolbar flat class="mb-5" color="transparent">
      <v-app-bar-nav-icon>
        <v-icon color="primary">mdi-qrcode</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title class="font-weight-bold">Movimientos CoDi</v-toolbar-title>

      <v-spacer></v-spacer>
      
      <v-btn
        outlined
        color="primary"
        @click="() => {
          if(isAuthenticated){
            $router.push(`/entities/${$route.params.entidadId}/account/orders/${$route.params.ocId}`).catch(()=>{})
          } else {
            $router.push(`/orders/${$route.params.ocId}`).catch(()=>{})
          }
        }"
      >
        Regresar
      </v-btn>
    </v-toolbar>

    <v-row>
      <v-col cols="12">
        <v-card class="shadow-sm">
          <v-simple-table class="pa-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Fecha de solicitud</th>
                  <th class="text-left">Fecha de Expiracion</th>
                  <th class="text-left">Monto</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in charges" :key="item.idc">
                  <td>{{ item.fechaSolicitud | toDate }}</td>
                  <td>{{ item.fechaExpiracion | toDate }}</td>
                  <td>{{ item.monto | currency }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="grey"
                      @click="() => {
                        if(isAuthenticated){
                          $router.push(`/entities/${$route.params.entidadId}/account/orders/${$route.params.ocId}/codi/${item.idc}${item.idp||''}`).catch(() => {})
                        } else {
                          $router.push(`/orders/${$route.params.ocId}/codi/${item.idc}${item.idp||''}`).catch(() => {})
                        }
                      }"
                      class="ma-2"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </td>
                </tr>

                <tr v-if="charges && charges.length == 0">
                  <td colspan="5" class="text-center caption">
                    No se han encontrado registros!
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
  },
  data() {
    return {
      charges: [],
    };
  },
  mounted() {
    this.getCharges();
  },
  methods: {
    getCharges() {
      const ocId = this.$route.params.ocId;

      axios
        .get(`api/v1/codi/charges?codigoInterno=${ocId}`)
        .then((res) => {
          const charges = _.get(res, "data.data");

          this.charges = charges;
        })
        .catch(() => {
          this.charges = [];
        });
    },
  },
};
</script>