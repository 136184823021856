var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-container"},[_c('v-toolbar',{staticClass:"mb-5",attrs:{"flat":"","color":"transparent"}},[_c('v-app-bar-nav-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-qrcode")])],1),_c('v-toolbar-title',{staticClass:"font-weight-bold"},[_vm._v("Movimientos CoDi")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function () {
        if(_vm.isAuthenticated){
          _vm.$router.push(("/entities/" + (_vm.$route.params.entidadId) + "/account/orders/" + (_vm.$route.params.ocId))).catch(function (){})
        } else {
          _vm.$router.push(("/orders/" + (_vm.$route.params.ocId))).catch(function (){})
        }
      }}},[_vm._v(" Regresar ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadow-sm"},[_c('v-simple-table',{staticClass:"pa-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Fecha de solicitud")]),_c('th',{staticClass:"text-left"},[_vm._v("Fecha de Expiracion")]),_c('th',{staticClass:"text-left"},[_vm._v("Monto")]),_c('th')])]),_c('tbody',[_vm._l((_vm.charges),function(item){return _c('tr',{key:item.idc},[_c('td',[_vm._v(_vm._s(_vm._f("toDate")(item.fechaSolicitud)))]),_c('td',[_vm._v(_vm._s(_vm._f("toDate")(item.fechaExpiracion)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.monto)))]),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","color":"grey"},on:{"click":function () {
                      if(_vm.isAuthenticated){
                        _vm.$router.push(("/entities/" + (_vm.$route.params.entidadId) + "/account/orders/" + (_vm.$route.params.ocId) + "/codi/" + (item.idc) + (item.idp||''))).catch(function () {})
                      } else {
                        _vm.$router.push(("/orders/" + (_vm.$route.params.ocId) + "/codi/" + (item.idc) + (item.idp||''))).catch(function () {})
                      }
                    }}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)])}),(_vm.charges && _vm.charges.length == 0)?_c('tr',[_c('td',{staticClass:"text-center caption",attrs:{"colspan":"5"}},[_vm._v(" No se han encontrado registros! ")])]):_vm._e()],2)]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }